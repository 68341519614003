<template>
    <div>
        <b-overlay :show="loading_component == true ? loading_component : loading" :opacity="0.6">

            <div class="h-100 app-local-container">
                    <b-card  class="app-local-right-card" no-body>
                        <b-row>
                            <b-col>
                                <b-row class="app-local-header">
                                    <div class="app-local-title">{{trans('d-contact-persons',287)}}</div>
                                    <app-refresh class="app-local-refresh" :refresh.sync="dashboardData.contact_persons.items.refresh" :loading.sync="loading_component == true ? loading_component : loading"/>
                                </b-row>
                                    
                                <b-list-group flush class="app-local-list-group-custom-style" :style="'max-height:'+listHeight+';min-height:'+listHeight">
                                    <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item in dashboardData.contact_persons.items.list" :key="item.id_user">
                                        <b-row class="w-100">
                                            <b-col start>
                                                <div class="app-local-title-name">{{ item.contact_name }}</div>
                                                <div class="app-local-subtitle">{{ item.function }}</div>
                                                <div class="container">
                                                    <div class="app-local-dot"></div>
                                                    <div class="app-local-details">{{ item.phone_number ? item.phone_number : ' - ' }}</div>
                                                </div> 
                                                <div class="app-local-field container" align-v="center">
                                                    <div class="app-local-dot"></div>
                                                    <div class="app-local-details">{{ item.contact_email }}</div>
                                                </div> 
                                            </b-col>
                                    
                                        </b-row>
                                    </b-list-group-item>
                                </b-list-group>
                                <div class="d-flex justify-content-center align-items-center app-table-no-records mt-2" v-if="dashboardData.contact_persons.items.total == 0">
                                    {{trans('no-records-to-display',281)}}
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>


            </div>  

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import axios from "@axios";

export default {

    data() {
        return {
            listHeight: {type: String, default: "140px"},
            loading_component:false,
        };
    },

    props: {
        loading: {}, 
        dashboardData: {},
    },

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cDashboardData: {
            get() { return this.dashboardData },
            set(value) { this.$emit('update:dashboardData', value) }
        },

    },

    methods: {
        getList(){
            this.loading_component = true;
            axios
                .get("dashboard/getDashboardContacts")
                .then((res) => {
                    this.cDashboardData.contact_persons = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_component = false;
                });

        },

        onCreateThis() { 

            this.$watch('dashboardData.contact_persons.items.refresh', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                } 
            });

        },
    }
};
</script>

<style scoped>
.app-local-right-card {
    flex: auto;
    border-radius: 0px 5px 5px 0px;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: stretch;
    border-radius: 10px !important;
}

.app-local-title{
    font-size:16px;
    font-weight: 600;
    margin-left: 18px;
}

.app-local-header{
    margin-top: 10px;
}

.app-local-title-name{
    font-size:0.95rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin-bottom: -4px !important;
}

.app-local-details{
    font-size:0.8rem;
    line-height: 1.125rem;

}

.app-local-subtitle{
    font-size:0.85rem;
    line-height: 1.5rem;
    margin-bottom: 5px

}

.container {
  display: flex;
}

.app-local-dot{
    min-width: 10px;
    min-height: 10px;
    max-height: 10px;
    max-width: 10px;
    border-radius: 20px;
    background-color: #ed8132;
    margin-right: 15px;
    margin-top: 4px;

}

.app-local-refresh{
    margin-left: auto;
    margin-right: 13px
}
</style>
