<template>
    <div>
        <!--<b-overlay :show="loading_component == true ? loading_component : loading" :opacity="0.6">-->
                    <b-card  class="app-local-right-card" body-class>
                        <b-row >
                            <b-col>
                                <b-row>
                                    <div class="app-local-title">{{ cOfficeName }}</div>
                                    <!--<app-refresh class="app-local-refresh" :refresh.sync="refresh" :loading.sync="loading_component == true ? loading_component : loading"/>-->
                                </b-row>
                                
                                <div class="app-local-subtitle">{{dashboardData.data.office_address ? dashboardData.data.office_address : trans('d-address', 287)}}</div>
                                <div class="app-local-field container">
                                    <div class="app-local-dot"></div>
                                    <div class="app-local-details">{{dashboardData.data.phone_number ? dashboardData.data.phone_number : trans('d-phone', 287)}}</div>
                                </div>
                                <div class="app-local-field container mb-0" align-v="center">
                                    <div class="app-local-dot"></div>
                                    <div class="app-local-details">{{dashboardData.data.office_email ? dashboardData.data.office_email : trans('d-email', 287)}}</div>
                                </div>                        
                            </b-col>
                        </b-row>
                    </b-card>
            
            <!--<template #overlay>
                <div></div>
            </template>
        </b-overlay>-->
    </div>
</template>

<script>
import axios from "@axios";

export default {

    data() {
        return {
            loading_component:false,
            refresh:0
        };
    },

    props: {
        loading: {}, 
        dashboardData: {},
    },

    watch:{
        "refresh": {
            handler: function(val) {
                this.getList();
            }
        }
    },

    computed: {
        cDashboardData: {
            get() { return this.dashboardData },
            set(value) { this.$emit('update:dashboardData', value) }
        },

        cOfficeName:{
            get(){
                if(this.dashboardData.data.office_name != null && this.dashboardData.data.office_name.includes('HappyNurse')){
                    return this.dashboardData.data.office_name;
                }else{
                    if(this.dashboardData.data.office_name == null){
                        return this.trans('d-office', 287);
                    }else{
                        return 'HappyNurse ' + this.dashboardData.data.office_name;
                    }
                    
                }
            }
        }

    },

    methods: {
        getList(){
            this.loading_component = true;
            axios
                .get("dashboard/getDashboardData")
                .then((res) => {
                    this.cDashboardData.data = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_component = false;
                });

        },
    }
};
</script>

<style scoped>
.container {
  display: flex;
}
.app-local-right-card {
    flex: auto;
    border-radius: 0px 5px 5px 0px;
    /*padding: 10px 20px 10px 20px;*/
    display: flex;
    align-items: stretch;
    background-color: #174066;
    color: white;
    border-radius: 10px !important;
}

.app-local-view{
    margin-top: 10px;
    padding-left: 4px;
    padding-right: 4px;
}

.app-local-title{
    font-size: 1.125rem ;
    font-weight: 600;
    line-height: 1.75rem;
    margin-left: 15px;

}

.app-local-subtitle{
    font-size:0.875rem;
    margin-bottom: 15px;
    line-height: 1.5rem;

}

.app-local-details{
    font-size:0.8rem;
    line-height: 1.125rem;

}

.app-local-field{
    background-color: white;
    margin-bottom: 10px;
    border-radius: 10px;
    color: #174066;
    padding: 5px 10px 5px 10px;
    max-height: 30px;
    max-width: 100%;
}
.app-local-dot{
    min-width: 10px;
    min-height: 10px;
    max-height: 10px;
    max-width: 10px;
    border-radius: 20px;
    background-color: #ed8132;
    margin-right: 15px;
    margin-top: 4px;

}

.app-local-refresh{
    margin-left: auto;
    margin-right: 13px
}
</style>
