<template>
    <b-overlay :show="loading_component == true ? loading_component : loading" :opacity="0.6">

        <b-card class="app-local-height" no-body>
            <b-row class="app-local-header">
                <div class="app-local-title">{{trans('d-declaration-and-hours', 287, {total_hours : dashboardData.hours.total_hours})}}</div>
                <app-refresh class="app-local-refresh" :refresh.sync="dashboardData.hours.refresh" :loading.sync="loading_component == true ? loading_component : loading"/>
            </b-row>
                <b-row>
                
                    <b-col class="app-local-right-border" >

                        <div class="app-local-first-line w-100">
                            <span class="app-local-first-line-span text-center"  >
                                {{dashboardData.hours.progressed_by_fw}}
                            </span>
                            <b-icon icon="person-lines-fill" class="app-local-first-line-icon"/>
                        </div>

                        <div class="w-100 text-center"> 
                            <div class="app-local-second-line w-100 text-center text-muted">
                                {{trans('d-submit-flexworker', 287)}}
                            </div>
                        </div>
                            
                    </b-col>

                    <b-col class="app-local-right-border" >
                            
                        <div class="app-local-first-line w-100">
                            <span class="app-local-first-line-span text-center" >
                                {{dashboardData.hours.approved_by_clients}}              
                            </span>
                            <b-icon icon="ui-checks" class="app-local-first-line-icon" />
                        </div>

                        <div class="w-100 text-center">
                            <div class="app-local-second-line w-100 text-center text-muted">
                                {{trans('d-still-to-be-approved', 287)}}
                            </div>
                        </div>                        
                            
                    </b-col>
                    <b-col class="" >

                        <div class="app-local-first-line w-100">
                            <span class="app-local-first-line-span text-center"  >
                                {{dashboardData.hours.progressed_by_hnu}}                
                            </span>
                            <b-icon icon="file-earmark-text" class="app-local-first-line-icon"/>
                        </div>

                        <div class="w-100 text-center">
                            <div class="app-local-second-line w-100 text-center text-muted">
                                {{trans('d-processing-hnu', 287)}}
                            </div>
                        </div>   
                            
                    </b-col>

                </b-row>

                <b-row class="app-buttons">
                    <app-button type="primary" @click="getHoursList(1)">{{ trans('d-approve-declarations', 287) }} </app-button>
                    <app-button type="secondary" @click="getHoursList(2)">{{ trans('d-declaration-overview', 287) }} </app-button>
                </b-row>

                
        </b-card>

        <template #overlay>
            <div></div>
        </template>
    </b-overlay>
</template>

<script>
import axios from "@axios";

export default {

    data() {
        return {
            loading_component:false,
            client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,
            ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main),
            ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main),
        };
    },

    props: {
        loading: {}, 
        dashboardData: {},
    },

    computed: {
        cDashboardData: {
            get() { return this.dashboardData },
            set(value) { this.$emit('update:dashboardData', value) }
        },
    },

    mounted() {
        this.onCreateThis();
    },

    methods: {
        getList(){
            this.loading_component = true;
            axios
                .post("dashboard/getDashboardHours",{
                    ids_location_data_main: JSON.stringify(this.ids_location_data_main.map(item=> item.value)),
                    ids_department_data_main: JSON.stringify(this.ids_location_data_main.map(item=> item.value)),
                    client_link: this.client_link
                })
                .then((res) => {
                    this.cDashboardData.hours = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_component = false;
                });

        },

        getHoursList(type){
            if(type == 1){
                this.$router.push({ name: "planning-offers-hours", params: {from_dashboard: type } });
            }else{
                this.$router.push({ name: "planning-offers-hours", params: {from_dashboard: type } });
            }
        },

        onCreateThis() { 

            this.$watch('dashboardData.hours.refresh', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                }   
            });

        },
    }
};
</script>

<style scoped>

.app-local-right-border {
     border-right: 1px solid #e6e6e6 !important;   
}

.app-local-first-line {
    
    margin-top: 10px;
    margin-left: 6px;
    flex: auto;
    display: flex;
    justify-content: center;
}

.app-local-first-line-span {
    align-self: flex-end;
    font-size: 40px;
    font-weight: 600;    
}

.app-local-first-line-icon {
    align-self: flex-start;    
    margin-top: 8px;
    margin-left: 4px;
    width: 16px;
    height: 16px;    
}

.app-local-second-line {
    height: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.1;
}

.app-local-height{
    min-height: 110px !important;
}



.app-buttons{
    padding: 20px 10px 10px 10px;
    margin-left: auto;
    margin-right: 25px;
}

.app-local-header{
    font-size:16px;
    font-weight: 600;
    padding: 15px;
    margin-left: 6px
}

.app-local-refresh{
    margin-left: auto;
    margin-right: 18px
}
</style>
