<template>
    <div>       
      
    <b-row class="match-height mt-4">
        <b-col md="12" lg="8" class="pr-lg-2">
            <dashboard-greetings-component class="component-match-height mb-3" :dashboardData.sync="dashboard_data" :loading.sync="loading"/>
        </b-col>

        <b-col md="12" lg="4" class="pl-lg-2">
            <dashboard-user-office-component class="component-match-height mb-3" :dashboardData.sync="dashboard_data" :loading.sync="loading"/>
        </b-col>
    </b-row>

    <b-row class="match-height">
        <b-col md="12" lg="8" class="pr-lg-2">
            <b-tabs ref="tabs" class="mb-2" justified>
                <b-tab :title="trans('services',280)" @click="refreshTab(0)" :active="activeTab == 0">
                    <dashboard-shifts-component class="component-match-height" :class="(userRole == 5 || (userRole == 6 && userHours == 1) ? 'mb-3' : '')" :dashboardData.sync="dashboard_data" :loading.sync="loading"/>
                </b-tab>  
                <b-tab :title="trans('assignments',280)" @click="refreshTab(1)" :active="activeTab == 1">
                    <dashboard-assignments-component class="component-match-height" :dashboardData.sync="dashboard_data" :loading.sync="loading"/>
                </b-tab>  
            </b-tabs>
            <dashboard-hours-component v-if="userRole == 5 || (userRole == 6 && userHours == 1)" :dashboardData.sync="dashboard_data" :loading.sync="loading"/>
        </b-col>
        <b-col md="12" lg="4" class="pl-lg-2">
                <!--<dashboard-contact-persons-component class="mb-3" :dashboardData.sync="dashboard_data" :loading.sync="loading"/>-->
                <dashboard-documents-component :dashboardData.sync="dashboard_data" :loading.sync="loading" class="component-match-height" />
            </b-col>
    </b-row>

    </div>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import DashboardGreetingsComponent from './Components/DashboardGreetingsComponent.vue';
import DashboardUserOfficeComponent from './Components/DashboardUserOfficeComponent.vue';
import DashboardContactPersonsComponent from './Components/DashboardContactPersonsComponent.vue';
import DashboardDocumentsComponent from './Components/DashboardDocumentsComponent.vue';
import DashboardShiftsComponent from './Components/DashboardShiftsComponent.vue';
import DashboardHoursComponent from './Components/DashboardHoursComponent.vue';
import DashboardAssignmentsComponent from './Components/DashboardAssignmentsComponent.vue';

export default {
    components: {
        DashboardGreetingsComponent,
        DashboardUserOfficeComponent,
        DashboardContactPersonsComponent,
        DashboardDocumentsComponent,
        DashboardShiftsComponent,
        DashboardHoursComponent,
        DashboardAssignmentsComponent
    },

    created(){
        this.getData();
        this.userHours = JSON.parse(localStorage.getItem('user-portal')).hours
        this.userRole = JSON.parse(localStorage.getItem('user-portal')).id_user_const_role
    },
    
    mounted() {
      
    },

    computed: {
        cOneTab: {
            get() {
                // if([1].includes(this.profile.assignment_data.id_assignment_const_status)) { 
                //     return true;
                // } else {
                //     return false;
                // }
                return true;
            }
        }   
    },

    data() {
        return {
            userName: JSON.parse(localStorage.getItem('user-portal')).user_name,
            userHours: null,
            userRole: null,
            loading: false,
            client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,
            ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main),
            ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main),
            dashboard_data: {
                data:{},
                shifts:[],
                contact_persons:{
                    items:{
                        list:[],
                        total:0,
                        refresh:0
                    },
                    page:1
                },
                shifts:{
                    items:{
                        list:[],
                        total:0,
                        total_open:0,
                        refresh:0
                    },
                    page:1
                },
                assignments:{
                    items:{
                        list:[],
                        total:0,
                        refresh:0
                    },
                    page:1
                },
                documents:{
                    items:{
                        list:[],
                        total:0,
                        refresh:0
                    }
                },
                hours:{
                    total_hours:0,
                    progressed_by_fw:0,
                    approved_by_clients:0,
                    progressed_by_hnu:0,
                    refresh:0
                }
            },
            activeTab: 0,
        };
    },
    
    methods: {
        /*getCurrentDate(){
            moment.locale('nl');
            var day = moment().format('dddd').charAt(0).toUpperCase() + moment().format('dddd').slice(1) + ' ';
            return day + moment().format('DD MMMM YYYY');
        },*/

        getData(){
            this.loading = true;
            axios
                .post("dashboard/getData",{
                    ids_location_data_main: JSON.stringify(this.ids_location_data_main.map(item => item.value)),
                    ids_department_data_main: JSON.stringify(this.ids_department_data_main.map(item => item.value)),
                    client_link: this.client_link
                })
                .then((res) => {
                    this.dashboard_data = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        refreshTab(e) {
            if (this.activeTab !== e) {
                this.activeTab = e;

                if (e == 0) {//Shifts
                    // this.profile.shifts.items.refresh++;
                } else if (e == 1) {//Assignments
                    // this.profile.shifts.items.refresh++;
                }
            }

        },
    },
};
</script>

<style scoped>


</style>
