<template>
    <div>
        
        <b-card  class="app-local-right-card" body-class>
            <b-row>
                <b-col md="8" sm="12">
                    <div class="app-local-title">{{trans('dashboard-welcome', 287,{userName: userName})}}</div>
                    <div class="app-local-subtitle">{{trans('d-welcome-message', 287)}}</div>
                    <div class="app-local-message">{{trans('d-welcome-message-part', 287)}}</div>
                </b-col>
                <b-col md="4" sm="12" class="mt-3 mt-md-0">
                    <b-alert variant="warning" show class="mb-0 app-local-warning">
                        <b-row class="d-flex align-items-center">
                            <b-col start cols="auto">
                                <div class="app-local-message-box">{{trans('d-welcome-orange-box', 287)}}</div>
                            </b-col>
                        </b-row>
                        <b-row class="d-flex align-items-center">
                            <b-col>
                                <b-button  class="list-button app-input-button-group app-icon-button my-auto ml-3" @click="addShift">
                                    <b-icon icon="plus" class="list-button-icon"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-alert> 
                </b-col>
            </b-row>
        </b-card>
        
    </div>
</template>

<script>

export default {

    data() {
        return {
            userName: JSON.parse(localStorage.getItem('user-portal')).user_name,
            loading_component: false
        };
    },

    props: {
        loading: {}, 
        dashboardData: {},
    },

    methods: {
        addShift(){
            this.$router.push({ name: "planning-offers-shifts-add", params: { } });
        },
    }
};
</script>

<style scoped>




.app-local-right-card {
    flex: auto;
    border-radius: 0px 5px 5px 0px;
    /*padding: 10px 20px 10px 20px;*/
    display: flex;
    align-items: stretch;
    border-radius: 10px !important;

}


.app-local-title{
    font-size:1.875rem;
    font-weight: 600;
    line-height: 2.375rem;
}

.app-local-subtitle{
    font-size: 1.125rem ;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.75rem;

}

.app-local-message{
    font-size:0.875rem;
    line-height: 1.25rem;

}

.app-local-message-box{
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25;
    color: white;
    padding: 10px;
    text-align: justify;
}

.app-local-warning{
    background-color: #ed8132 !important;
    border-color: #ed8132 !important;
}

.list-button {
    border:1px solid #ced4da; 
    margin-left:8px; 
    border-radius: 30px; 
    padding: 0px; 
    min-width:31px; 
    min-height:31px; 
    max-height:31px;
    position: relative;
    float: right;
}

.list-button:hover {
    border-color: #0D7BB3;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1) ;
}
.list-button:focus-visible{
    background: #ced4da !important;
    border-color: #ced4da !important;
    box-shadow: 0px 0px 5px -2px rgb(13 126 179) !important;
}
.list-button-icon {
    min-width:20px; 
    min-height:20px;
    color:#ed8132
}

.list-button.disabled:hover, .list-button:disabled {
    border-color: #ced4da;
    box-shadow: none ;
    background: transparent !important;
}
</style>
