<template>

    <b-overlay :show="loading_component == true ? loading_component : loading" :opacity="0.6">

        <b-card  class="app-local-right-card" no-body>
            <b-row class="app-local-view">
                <b-col>
                    <b-row>
                        <div class="app-local-header">{{trans('d-current-shifts', 287)}}</div>
                        <app-refresh class="app-local-refresh" :refresh.sync="dashboardData.shifts.items.refresh" :loading.sync="loading_component == true ? loading_component : loading"/>
                    </b-row>
                            
                    <b-list-group flush class="app-local-list-group-custom-style" :style="'max-height:'+listHeight+';min-height:'+listHeight">
                        <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item, index in dashboardData.shifts.items.list" :key="index">
                            <b-row class="w-100" align-v="center">

                                <b-col xl="3" lg="3" md="3" sm="12" start>
                                    <b-row class="align-items-center pl-sm-2 pl-md-0">
                                        <div class="app-local-icon-background ">
                                            <b-icon icon="geo-alt-fill" font-scale="1.8" style="color: white"></b-icon>     
                                        </div>
                                        <b-col>
                                            <div class="app-local-title">{{ item.location_name }}</div>
                                            <div class="app-local-subtitle">{{ item.department }}</div>
                                        </b-col>
                                    </b-row>
                                </b-col>

                                <b-col xl="3" lg="3" md="3" sm="12" class="mt-2 mt-md-0">
                                    <b-row class="align-items-center pl-sm-2 pl-md-0">
                                        <div class="app-local-icon-background">
                                            <b-icon icon="calendar2-date-fill" font-scale="1.8" style="color: white"></b-icon>     
                                        </div>
                                        <b-col>
                                            <div class="app-local-title">{{ item.date_from }}</div>
                                            <div class="app-local-subtitle">{{trans('d-from', 287)}} {{ item.hour_from }} {{trans('d-to', 287)}} {{ item.hour_to }}</div>
                                            <div class="app-local-subtitle">{{ item.shift_function_name }}</div>
                                        </b-col>
                                    </b-row>
                                </b-col>                                       
                                
                                <b-col xl="3" lg="3" md="3" sm="12" class="mt-2 mt-md-0">
                                    <b-row class="align-items-center pl-sm-2 pl-md-0">
                                        <b-col>
                                            <b-row>
                                                <div :id="'popover-target-'+item.id_shift_data_main">
                                                    <div class="ml-2 app-local-title">{{ item.person_name }}</div>
                                                    <div class="ml-2 app-local-subtitle"> {{ item.applicant_function_name }} </div>
                                                </div>
                                            </b-row>
                                        </b-col>

                                    </b-row>
                                </b-col>

                                <b-popover :target="'popover-target-'+item.id_shift_data_main" triggers="hover" no-fade custom-class="custom-popover-header custom-popover" placement="bottom">
                                        <template #title>
                                            <b-row>
                                                <b-col cols="8" class="pl-3">
                                                    <div style="margin-bottom: -25px;" class="mt-1 d-flex flex-nowrap">{{item.person_name_alert}}</div><br />
                                                    <span class="app-local-main-function">{{item.person_function_name}}</span><br />
                                                    <span class="app-local-additional-function">{{ item.person_function_name_additional }}</span>
                                                </b-col>
                                            </b-row>
                                        </template>
                                        <span class=""><b>Emailadress</b></span>
                                        <b-alert show class="p-2 alert-color">{{item.email}}</b-alert>

                                        <span class=""><b>Telefoonnummer</b></span>
                                        <b-alert show class="p-2 alert-color">{{item.person_phone_number}}</b-alert>
                                        
                                        <span class=""><b>Geboortedatum</b></span>
                                        <b-alert show class="p-2 alert-color">{{item.birth_date}}</b-alert>

                                </b-popover>

                                <b-col xl="1" lg="1" md="1" sm="12" class="mt-2 mt-md-0">
                                    <h2><app-badge type="neutral" class="app-local-badge">{{ item.person_type }}</app-badge></h2>
                                </b-col>

                                <b-col xl="2" lg="2" md="2" sm="12" class="mt-1 mt-md-0 text-md-right">
                                    <h2><app-badge type="pending" class="app-local-badge">{{item.shift_status}}</app-badge></h2>
                                </b-col>
                        
                            </b-row>
                        </b-list-group-item>
                        <hr class="mt-2 mb-1" />

                    </b-list-group>
                    <hr class="mt-2 mb-1" v-if=" dashboardData.shifts.items.total == 0"/>
                    <div class="d-flex justify-content-center align-items-center app-table-no-records mt-2" v-if=" dashboardData.shifts.items.total == 0">
                        {{trans('no-records-to-display',281)}}
                    </div>
                    <hr class="mt-2 mb-1" v-if=" dashboardData.shifts.items.total == 0"/>

                    <b-row class="app-buttons">
                        <app-button type="primary" @click="getShiftsList">{{trans('sh-accept',292)}} ({{ dashboardData.shifts.items.total_open }})</app-button>
                        <app-button type="secondary" @click="getShiftsListAll">{{trans('d-service-overview', 287)}} </app-button>
                    </b-row>
                </b-col>
            </b-row>


        </b-card>

        <template #overlay>
            <div></div>
        </template>
    </b-overlay>

</template>

<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
    },

    data() {
        return {
            listHeight: {type: String, default: "140px"},
            loading_component:false,
            alert_class: new AppAlerts(),
            client_link: JSON.parse(localStorage.getItem('user-portal')).client_link,
            ids_location_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_location_data_main),
            ids_department_data_main: JSON.parse(JSON.parse(localStorage.getItem('user-portal')).ids_department_data_main),
        };
    },

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cDashboardData: {
            get() { return this.dashboardData },
            set(value) { this.$emit('update:dashboardData', value) }
        },

    },

    props: {
        loading: {}, 
        dashboardData: {},
    },

    methods: {

        getShiftsList(){
            this.$router.push({ name: "planning-offers-shifts", params: {from_dashboard: 1 } });
        },

        getShiftsListAll(){
            this.$router.push({ name: "planning-offers-shifts", params: {from_dashboard: 2 } });
        },

        getList(){
            this.loading_component = true;
            axios
                .post("dashboard/getDashboardShifts",{
                    ids_location_data_main: JSON.stringify(this.ids_location_data_main.map(item=> item.value)),
                    ids_department_data_main: JSON.stringify(this.ids_location_data_main.map(item=> item.value)),
                    client_link: this.client_link
                })
                .then((res) => {
                    this.cDashboardData.shifts = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_component = false;
                });

        },

        confirm(item){
            this.alert_class.openAlertConfirmation(this.trans('pl-employee-confirmation', 291, {person_name : item.person_name})).then(res => {

                if(res == true){

                   /* this.loading_component = true;

                    axios
                    .post("shifts/person/confirm", {
                        id_shift_ref_applicants: item.id_shift_ref_applicants,
                        id_person_data_main: item.id_person_data_main,
                        id_shift_data_main: item.id_shift_data_main,
                        return_type: 'shifts_dashboard',
                    })
                    .then((res) => {                 
                        this.cDashboardData.shifts = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                    
                    this.loading_component = false;
                    });*/

                }

            });
        },

        onCreateThis() { 

            this.$watch('dashboardData.shifts.items.refresh', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                }   
            });


        },

    }
};
</script>
<style>
.custom-popover{
    min-width: 400px !important;
}</style>
<style scoped>
.app-local-icon-background{
    background-color: #174066;
    padding: 5px;
    margin-left: 5px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 5px;
    color: #fff;

}

.app-local-right-card {
    flex: auto;
    border-radius: 0px 5px 5px 0px;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: stretch;
    border-radius: 10px !important;
}

.app-local-view{
    margin-top: 10px;
    padding-left: 4px;
    padding-right: 4px;
}

.app-local-header{
    font-size:16px;
    font-weight: 600;
    margin-left: 13px;
    margin-bottom: 3px;
}
.app-local-title{
    font-size:14px;
    font-weight: 600;
}

.app-local-subtitle{
    font-size:12px;
}

.app-buttons{
    padding: 10px;
    margin-left: auto;
    margin-right: 25px;
    float: right;
}

.app-local-refresh{
    margin-left: auto;
    margin-right: 10px
}

.custom-avatar{
    height: 42px;
    width: 42px;
}

.custom-avatar-popover{
    height: 70px;
    width: 70px;
}


.app-local-first-col{
    margin-left:5px
}

h2 {
    font-size: 1.25rem;
  }

  .alert-color{
    color: #174066 !important;
}

.app-local-main-function{
    font-weight: 600;
    font-size: 13px;
}

.app-local-additional-function{
    font-weight: 300;
    font-size: 12px;
}

@media (min-width: 767px){
    h2 {
        font-size: .8rem;
    }
}
  
@media (min-width: 1400px) {
    h2 {
        font-size: 1rem;
    }
}

@media (min-width: 1700px) {
    
    h2 {
        font-size: 1.25rem;
    }
}



</style>
