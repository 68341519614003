<template>
    <b-card v-if="userRole == 5 || (userRole == 6 && userPlacement == 1)">
        <app-view-table :title="trans('cp-documents', 286)" :loading="loading_component == true ? loading_component : loading" :isMenuColumn="true" :isSearch="false" :isTitleTotal="true" :filters.sync="filters" :items="dashboardData.documents.items" :fields="fields" :key="refresh" :isDropdownMenu="true"  resizeableColumns resizeMinWidth v-on:onRowClicked="item=>openDocument(item)" :rowPointerCursor="true">
            <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('cp-show-document',286)}}</b-dropdown-item>
            </template>
        </app-view-table>
    </b-card>  
</template>
<script>
 

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

  export default {
    components: {
    },

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user-portal')).id_user_const_role
        this.userPlacement = JSON.parse(localStorage.getItem('user-portal')).placement
    },

    mounted() {
        this.onCreateThis();
    },

    props: {
        loading: {}, 
        dashboardData: {},
    },

    computed: {
        cDashboardData: {
            get() { return this.dashboardData },
            set(value) { this.$emit('update:dashboardData', value) }
        },

    },

    data() {
        return {
            alert_class: new AppAlerts(),
            refresh:0,    
            loading_component:false,        
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {   
                    document_types: {type: 'list', changed: 0, values: [], function: "getDocumentTypesPortal", parameters: null, widthClass:'app-width-min-250'},                      
                    dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'}, 
                    persons: {type: 'list', changed: 0, values: [], function: "getDocumentPersonsPortal", parameters: null, widthClass:'app-width-min-250'},                                                                          
                },
                page: 1,
                per_page: 5,
                changed: 0,
                search: ""
            },
            fields: [
                { key: "document_type_name", label: this.trans('cp-document-type', 286), thStyle: { "min-width": "180px", "max-width": "180px" }, visible: true, filtering: true, filtering_name: "document_types"},
                { key: "entry_date", label: this.trans('pl-date', 288), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "placement_person_name", label: this.trans('doc-person-name', 290), thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "persons"},    
            ],
        };
    },

    methods: {
        getList(){
            this.loading_component = true;
            axios
                .post("clients/documents/getList", {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.cDashboardData.documents = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_component = false;
                });

        },

        openDocument(data){
            this.loading_component = true;
            axios
                .post("clients/documents/downloadFile", {
                    id_document_data_main: data.id
                }, 
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {

                    const url = window.URL.createObjectURL(new Blob([res.data], {type : data.mine}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                     data.mine == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                     data.mine == 'application/msword' ||
                     data.mine == 'text/plain'
                                     ? link.setAttribute('download', data.document_name):link.setAttribute('open', data.document_name); 
                    link.setAttribute('open', data.document_name); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    //console.error(error);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',281)).then(res => {})
                })
                .finally(() => {
                   this.loading_component = false;
                });
        },

        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                } 
            });

            this.$watch('dashboardData.documents.items.refresh', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.document_types.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.persons.changed', function(newVal, oldVal) {
                if (!this.loading && !this.loading_component) {
                    this.getList();
                } 
            });

        },

    },
  };
</script>
<style scoped>

</style>
